define("discourse/plugins/chat/discourse/components/thread-unread-indicator/index", ["exports", "@glimmer/component", "@ember/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_UNREAD_COUNT = 99;
  class ChatThreadUnreadIndicator extends _component.default {
    get unreadCount() {
      return this.args.thread.tracking.unreadCount;
    }
    get urgentCount() {
      return this.args.thread.tracking.watchedThreadsUnreadCount;
    }
    get showUnreadIndicator() {
      return this.unreadCount > 0 || this.urgentCount > 0;
    }
    get unreadCountLabel() {
      const count1 = this.urgentCount > 0 ? this.urgentCount : this.unreadCount;
      return count1 > MAX_UNREAD_COUNT ? `${MAX_UNREAD_COUNT}+` : count1;
    }
    get isUrgent() {
      return this.urgentCount > 0 ? "-urgent" : "";
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showUnreadIndicator}}
          <span class="chat-thread-list-item-unread-indicator {{this.isUrgent}}">
            <span class="chat-thread-list-item-unread-indicator__number">
              {{this.unreadCountLabel}}
            </span>
          </span>
        {{/if}}
      
    */
    {
      "id": "uXH+msT6",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showUnreadIndicator\"]],[[[1,\"      \"],[10,1],[15,0,[29,[\"chat-thread-list-item-unread-indicator \",[30,0,[\"isUrgent\"]]]]],[12],[1,\"\\n        \"],[10,1],[14,0,\"chat-thread-list-item-unread-indicator__number\"],[12],[1,\"\\n          \"],[1,[30,0,[\"unreadCountLabel\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/thread-unread-indicator/index.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatThreadUnreadIndicator;
});